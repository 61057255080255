import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BigHeader from "../components/bigHeader"

const Salmon = ({ contentField }) => {
  const lang = "en"
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout lang={lang}>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <a
        href="https://linkedin.com/company/hiddenfjord/posts/?feedView=all"
        target="_blank"
        rel="noreferrer"
      >
        <BigHeader image={require("../assets/images/salmon-back.jpg")} />
      </a>

      <div className="main-section">
        <div
          className="text-container text-container--salmon"
          dangerouslySetInnerHTML={{ __html: contentField.content }}
        />
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDozMTU4" }) {
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <Salmon {...data} />}
  />
)
